let config = {
    game: {
        bonuses: {
            exchangeBonus: 5000,
            bestTraderBonus: 1400,
        },
        prices: {
            valutaPurchaseRate: 4.95,
            valutaSellRate: 5.15,
            devizaPurchaseRate: 4.98,
            devizaSellRate: 5.05,
        },
        difficulty: {
            1: {
                rounds: 3,
                initialStockNumber: 4,
                companies: [{
                    name: "Pear Inc.",
                },
                {
                    name: "Bios Inc.",
                },
                {
                    name: "SpitzenModell AG",
                },
                {
                    name: "Fastfood Co.",
                }
            ]
            },
            2: {
                rounds: 5,
                initialStockNumber: 6,
                companies: [{
                    name: "Pear Inc.",
                },
                {
                    name: "One-Cloud Inc.",
                },
                {
                    name: "Bios Inc.",
                },
                {
                    name: "Just for fun Co.",
                },
                {
                    name: "SpitzenModell AG",
                },
                {
                    name: "Fastfood Co.",
                }
            ]

            },
            3: {
                rounds: 7,
                initialStockNumber: 6
            }
        }
    },
    settings: {
        pollSpeed: 2000,
        timer: 5000,
        inactivityTime: 30000,
        languageChangeSpeed: 2000,
        marketTime: 30,
        resultTime: 2,
        waitingTime: 2,
        waitingForLogout: 5,
        difficultyWaitingTime: 20,
        exchangeWaitinTime: 20,
        singlePlayerBalanceWaitingTime: 10000,
        groupBalanceWaitingTime: 8000,
        newsSwitchTime: 5000,
    }
}

export default config;