<template>
<div id="login">
  <div class="container">

        <h1>Login</h1>
        <div>

        <input type="text" name="username" v-model="input.username" placeholder="Username" />
        </div>
        <div>

        <input type="password" name="password" v-model="input.password" placeholder="Password" />
        </div>
        <div>

        <button  type="button" @click="login()">Login</button>
        </div>
  </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import CircleCountdown from '../components/CircleCountdown2.vue';
import MarketTimer from '../components/MarketTimer.vue';
import { mapMutations } from 'vuex';

export default {
  name: "Home",
  components: {
    HelloWorld,
    MarketTimer,
    CircleCountdown
  },
  data: function() {
    return {
      input: {
            username: "",
            password: ""
        }
    }
  },
  
  methods: {
    ...mapMutations(["setAuthenticated"]),
    login() {
      this.$http.post("/login", {username: this.input.username, password: this.input.password}).then(res => {
        console.log(res)
        this.setAuthenticated(true);
        this.$router.push('idle/1')
      }
      ).catch(err => console.log(err));
    }
  }
};
</script>

<style>
</style>
