<template>
  <div id="app">
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <InactiveChecker v-if="this.$store.state.modalShow"></InactiveChecker>
    </transition>
    
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view @authenticated="setAuthenticated" />
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import InactiveChecker from "./components/InactiveChecker.vue"
export default {
  name: "App",
  data: function() {
    return {
      standbyTimeout: null,
      mockAccount: {
                    username: "nraboy",
                    password: "password"
                }
    }
  },
  created() {
    // this.pollDevice();
  },
  components: {
    InactiveChecker
  },
  mounted() {
    console.log("MOUNTED APP");
    this.changeLanguage();
    if(!this.authenticated) {
                this.$router.replace("/home");
            }
    this.inactivityChecker();
    //this.$http.get('/gameid/')
  },
  computed: {
    ...mapGetters([
      "polling",
      "isGameStarted",
      "deviceId",
      "language",
      "languageInterval",
      "gameplayId",
      "state",
      "groupId",
      "authenticated"
      //"standbyTimeout"
      // ...
    ]),
  },
  methods: {
    ...mapMutations([
      "setPolling",
      "setIsGameStarted",
      "setLanguageInterval",
      "setLanguage",
      //"setStandbyTimeOut",
      "setBestUserId",
    ]),

    inactivityChecker() {
      console.log("INACTIVITY START");
      var self = this;
      function refresh() {
        console.log("REFRESHED");
        clearTimeout(self.standbyTimeout);
          self.standbyTimeout =  setTimeout(() => {
            if((self.$router.currentRoute.name == "Result" || 
                 self.$router.currentRoute.name == "Rating" || 
                 self.$router.currentRoute.name == "Exit" || 
                 self.$router.currentRoute.name == "PersonCheck" ||
                 self.$router.currentRoute.name == "Volume"
                 )) {
              self.$store.commit('setModalShow', true);
            } 
        }, self.$config.settings.inactivityTime)
      }
      refresh();
      
      //window.addEventListener("popstate", refresh);
      document.addEventListener("touchmove", refresh);
      document.addEventListener("touchstart", refresh);
      document.addEventListener('click', refresh);
    },

    changeLanguage() {
      var self = this;
      let langs = ["hu", "en", "cn"];
      let i = 0;

      if (this.$store.getters.language == null) {
        this.setLanguageInterval(
          setInterval(() => {
            self.$i18n.locale = langs[i % 3];
            self.$i18n.fallbackLocale = langs[i % 3];
            i++;
          }, this.$config.settings.languageChangeSpeed)
        );
      }
    },
    setAuthenticated(status) {
                this.authenticated = status;
            },

    async pollDevice() {
      this.setPolling(
        setInterval(() => {
          this.$http.get(`/device/${this.deviceId}`).then(async (response) => {
            this.$store.commit('setState', response.data.state);
            if(response.data.state == "GroupReserve" && this.$route.path != "/cantplay") {
              this.$router.push("/cantplay");
            }
            if (response.data.visitorId) {
              clearInterval(this.polling);
              this.setIsGameStarted(true);
              
              try {
                await this.$store.dispatch("fetchGameTurns");
                await this.$store.dispatch("fetchGameId", this.$store.getters.deviceId);
                await this.$store.dispatch("fetchVisitor", response.data.visitorId);
              } catch (error) {
                // console.log("err",error);
              }
              this.$store.dispatch("storeGameplayEvent", {
                round: 0,
                event: "logged_in",
              });
              
              if(this.groupId) {
                return this.$router.push('/group-start')
              }
              this.$router.push("/volume");
            }
          });
        }, this.$config.settings.pollSpeed)
      );
    },
    pollStatus() {
      //// console.log(this.$store);
      this.setPolling(
        setInterval(() => {
          //Get gameplayId from store.
          if(!this.gameplayId) return;
          this.$http.get(`/game-status/${this.gameplayId}`).then((response) => {
            //Exit
            if (response.data.status === "exit") {
              this.$store.dispatch("exitGame", this.$store.getters.deviceId);
            }
            if(response.data.best_user_id !== null) this.$store.commit('setBestUserId', response.data.best_user_id); 
            this.$store.commit("setStopGameTurnId", response.data.status);
          });
        }, this.$config.settings.pollSpeed)
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
    clearTimeout(this.standbyTimeout);
  },
  watch: {
    isGameStarted(newGameActive, oldGameActive) {
      if (newGameActive === true && oldGameActive === false) {
        // console.log("statusWatcher");
        return this.pollStatus();
      }
      if (oldGameActive === true && newGameActive === false) {
        // console.log("Devicewatcher");
        //return  this.pollDevice();
      }
    },
    deviceId(newDeviceId, oldDeviceId) {
      if (newDeviceId) {
        this.pollDevice();
      }
    },
    language(newLanguage) {
      // console.log("newLang", newLanguage);
      if (newLanguage != null) {
        clearInterval(this.languageInterval);
        this.setLanguage(newLanguage);
        this.$i18n.locale = newLanguage;
        this.$i18n.fallbackLocale = newLanguage;
      } else {
        this.changeLanguage();
      }
    },
    state(newState, oldState) {
      // console.log(oldState)
      if(oldState === "GroupReserve") {
        this.$router.push('/idle/' + this.$store.getters.deviceId)
      }
    },
    $route(newRoute,oldRoute) {
      // console.log("ROUTE WATCHER start");
      // console.log(newRoute);
      var self = this;
      clearTimeout(this.standbyTimeout);
      this.standbyTimeout =  setTimeout(() => {
          if((self.$router.currentRoute.name == "Result" || 
                 self.$router.currentRoute.name == "Rating" || 
                 self.$router.currentRoute.name == "Exit" || 
                 self.$router.currentRoute.name == "PersonCheck" ||
                 self.$router.currentRoute.name == "Volume"
                 )) {
                this.$store.commit('setModalShow', true);
          }
      }, self.$config.settings.inactivityTime)
      console.log("LEFUTOT A ROUTE WATCHER")

      /* var self = this;
      clearTimeout(this.standbyTimeout);
          self.$store.commit('setStandbyTimeout', setTimeout(() => {
            if(!(this.$router.currentRoute.name == "Standby" || 
                 this.$router.currentRoute.name == "Idle" || 
                 this.$router.currentRoute.name == "CantPlay" || 
                 this.$router.currentRoute.name == "GroupWelcome" ||
                 this.$router.currentRoute.name == "Video" ||
                 this.$router.currentRoute.name == "EndVideo" ||
                 this.$router.currentRoute.name == "Balance")) {
                this.$router.push("/standby");
            }
        }, self.$config.settings.inactivityTime)) */
      // console.log("LEFUTOT A ROUTE WATCHER")
    } 
  }, 
};
</script>

<style >
@import "./assets/styles/style.css";
</style>
