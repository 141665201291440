import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/idle/:id',
    name: 'Idle',
    component: () => import('../views/Idle.vue')
  },
  {
    path: '/cantplay',
    name: 'CantPlay',
    component: () => import('../views/CantPlay.vue')
  },
  {
    path: '/personcheck',
    name: 'PersonCheck',
    component: () => import('../views/PersonCheck.vue')
  },
  {
    path: '/exit',
    name: 'Exit',
    component: () => import('../views/Exit.vue')
  },
  {
    path: '/standby',
    name: 'Standby',
    component: () => import('../views/Standby.vue')
  },
  {
    path: '/volume',
    name: 'Volume',
    component: () => import('../views/Volume.vue')
  },
  {
    path: '/waiting',
    name: 'Waiting',
    component: () => import('../views/GroupWaiting.vue')
  },
  {
    path: '/waitingcounter',
    name: 'WaitingCounter',
    component: () => import('../views/WaitingCounter.vue')
  },
  {
    path: '/waitingcounter2',
    name: 'WaitingCounter2',
    component: () => import('../views/WaitingCounter2.vue')
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue')
  },
  {
    path: '/group-welcome',
    name: 'GroupWelcome',
    component: () => import('../views/GroupWelcome.vue')
  },
  {
    path: '/difficulty',
    name: 'Difficulty',
    component: () => import('../views/Difficulty.vue')
  },
  {
    path: '/rating',
    name: 'Rating',
    component: () => import('../views/Rating.vue')
  },
  {
    path: '/exchange',
    name: 'Exchange',
    component: () => import('../views/Exchange.vue')
  },
  {
    path: '/round',
    name: 'Round',
    component: () => import('../views/Round.vue')
  },
  {
    path: '/securities-exchange',
    name: 'SecuritiesExchange',
    component: () => import('../views/SecuritiesExchange.vue')
  },
  {
    path: '/rate-exchange',
    name: 'RateExchange',
    component: () => import('../views/RateExchange.vue')
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/Balance.vue')
  },
  {
    path: '/balance-waiting',
    name: 'BalanceWaiting',
    component: () => import('../views/BalanceWaiting.vue')
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import('../views/Video.vue')
  },
  {
    path: '/end-video',
    name: 'EndVideo',
    component: () => import('../views/Video2.vue')
  },
  {
    path: '/end',
    name: 'End',
    component: () => import('../views/End.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('../views/Result.vue')
  },
  {
    path: '/group-start',
    name: 'GroupStart',
    component: () => import('../views/GroupStart.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
