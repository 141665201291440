import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'animate.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import API from './api';
import config from '../config.js';
import i18n from './i18n'

Vue.config.productionTip = false;
Vue.prototype.$http = API;
Vue.prototype.$config = config;

Vue.mixin({
  methods: {
    exit: function () {
      this.$router.push("/exit");
    },
  },
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');