import API from "../../api.js";

const visitorModule = {
    state: () => ({
        visitorId: null,
        //sisActive: false,
        nickname: "",
        groupId: null,
        status: "",
        language: "",
        difficulty: "",
        birthYear: 1991,
        country: "",
        city: "",
        postalCode: 0,
        isChild: false,
        isGroup: false,
        balance: 50000,
        ogBalance: 30000,
        playedGames: [],
        //rfidType: null,
      }),
      
      mutations: {
        setVisitor (state, payload) {
          // console.log(payload);
          // `state` is the local module state
          //state.isActive= payload.isActive,
          //state.rfidType = payload.rfidType
        state.visitorId= payload.visitorId,
        state.nickname= payload.nickname,
        state.groupId= payload.groupId,
        state.status= payload.status,
        state.language= payload.language,
        state.difficulty= payload.difficulty
        state.birthYear= payload.birthYear
        state.country = payload.country
        state.city = payload.city
        state.postalCode = payload.postalCode,
        state.isChild = payload.isChild,
        state.isGroup = payload.isGroup,
        state.balance = payload.balance,
        state.ogBalance = payload.balance,
        state.playedGames = payload.playedGames
        },
        setBalance(state,payload) {
          state.balance = payload;
        } 
      },
      actions: {
        async fetchVisitor(state, visitorId) {
          return new Promise((resolve,reject) => {
            API.get(`/visitor/${visitorId}`).then(response => {
              state.commit('setVisitor',response.data);
              resolve(response);
            }).catch(error => {
              reject(error);
            });
          })
            
         },
      },
      getters: {
        getVisitor: (state) => state,
        groupId: (state) => state.groupId,
        isGroup: (state) => state.isGroup,
        balance: (state) => state.balance,
        ogBalance: state => state.ogBalance,
        nickname: state => state.nickname,
        visitorId: state => state.visitorId,
      },
}

export default visitorModule;