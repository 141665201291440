<template>
  <div class="timer mb-64">
    <svg viewBox="-10 -10 220 220">
      <path
        id="timer-circle"
        class="timer-circle"
        d="M0,100 a100,100 0 1 0 200,0 a100,100 0 1 0 -200,0"
        stroke="#fff"
        stroke-width="20"
      />
      <path
        id="timer-path-remaining"
        class="timer-path-remaining"
        d="M100,0 a100,100 0 0 1 0,200 a100,100 0 0 1 0,-200,0"
        stroke="#2395EE"
        stroke-width="20"
        stroke-dasharray="630"
        :stroke-dashoffset="circleDasharray"
      />
    </svg>
    <span id="timer-label" class="timer-label"> {{timeLeft}} </span>
  </div>
</template>

<script>
export default {
  props: ["time"],
  data() {
    return {
      timeLimit: this.time,
      ogTime: null,
      timePassed: 0,
      timeLeft: this.time,
      timeInterval: null,
      remainingPathColor: "green",
      fullDashArray: 0,
      warningTreshold: 10,
      alertTreshold: 5,
     circleDasharray: 0,

    };
  },
  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.$emit('end');
    },

    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed = this.timePassed += 1;
        this.timeLeft -= 1;
        this.setCircleDasharray();
        //this.setRemainingPathColor(this.timeLeft);

        if (this.timeLeft === 0) {
          this.onTimesUp();
        }
      }, 1000);
    },

    formatTime(time) {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    setRemainingPathColor(timeLeft) {
      const { alert, warning, info } = COLOR_CODES;
      if (timeLeft <= alert.threshold) {
        document
          .getElementById("base-timer-path-remaining")
          .classList.remove(warning.color);
        document
          .getElementById("base-timer-path-remaining")
          .classList.add(alert.color);
      } else if (timeLeft <= warning.threshold) {
        document
          .getElementById("base-timer-path-remaining")
          .classList.remove(info.color);
        document
          .getElementById("base-timer-path-remaining")
          .classList.add(warning.color);
      }
    },

    calculateTimeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    setCircleDasharray() {
      if(this.timePassed * (630/this.ogTime) <= 630) {
        this.circleDasharray = this.timePassed * (630/this.ogTime);
      }
    }
  },
  created() {
    this.timeLeft = this.time;
    this.ogTime = (this.time-1);
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
};
</script>

<style>

</style>
