import Vue from "vue";
import Vuex from "vuex";
import API from "../api.js";
import axios from 'axios';
import router from '../router/index.js';
import VisitorModule from "./modules/VisitorModule";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    language: 'hu',
    difficulty:  1,
    deviceId: null,
    volume: 50,
    reservedByGroupId: null,
    status: null,
    gameplayId: null,
    groupArrivalTime: null,
    polling: null,
    isGameStarted: false, //Elkezdte a játékot v sem. Devicepoll / Status poll
    gameId: null,
    routeName: null,
    languageInterval: null,
    exchangeDecision: "sellDeviza",
    deviza: 2000,
    securitiesAmountSum: 4123,
    devizaBonus: false,
    profit: 0,
    gameTurns: null,
    actualGameTurnId: null,
    stopGameTurnId: null,
    state: null,
    //standbyTimeout: null,
    newsGroupIndex: null,
    bestUserId: null,
    modalShow: false,
    authenticated: false,
  };
};

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    setModalShow(state, payload) {
      state.modalShow = payload;
    },
    setDeviceId(state, payload) {
      state.deviceId = payload;
    },
    setRouteName(state, payload) {
      state.routeName = payload;
    },
    setLanguage(state, payload) {
      state.language = payload;
    },
    setDifficulty(state, payload) {
      state.difficulty = payload;
    },
    setState(state, payload) {
      state.state = payload;
    },
    setGameId(state, payload) {
      state.gameId = payload;
    },
    setVolume(state, payload) {
      state.volume = payload;
    },
    setReservedByGroupId(state, payload) {
      state.reservedByGroupId = payload;
    },
    setGroupArrivalTime(state, payload) {
      groupArrivalTime = payload;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setIsGameStarted(state, payload) {
      state.isGameStarted = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
    setPolling(state, payload) {
      state.polling = payload;
    },
    setGameplayId(state, payload) {
      state.gameplayId = payload;
    },
    setLanguageInterval(state, payload) {
      state.languageInterval = payload;
    },
    setExchangeDecision(state,payload) {
      state.exchangeDecision = payload;
    },
    setDeviza(state,payload) {
      state.deviza = payload;
    },
    setSecuritiesAmountSum(state,payload) {
      state.securitiesAmountSum = payload;
    },
    setDevizaBonus(state,payload) {
      state.devizaBonus = payload;
    },
    addProfit(state,payload) {
      state.profit += payload;
    },
    setGameTurns(state,payload) {
      state.gameTurns = payload;
    },
    setActualGameTurnId(state,payload) {
      state.actualGameTurnId = payload;
    },
    setStopGameTurnId(state,payload) {
      state.stopGameTurnId = payload;
    },
    /* setStandbyTimeout(state,payload) {
      state.standbyTimeout = payload;
    }, */
    setNewsGroupIndex(state,payload) {
      state.newsGroupIndex = payload;
    },
    setBestUserId(state,payload) {
      state.bestUserId = payload;
    },
    setAuthenticated(state,payload) {
      state.authenticated = payload;
    }
  },
  actions: {
    //Getvisitor,
    exitGame(state, devId) {
      // console.log(state);
      clearInterval(this.polling);
      state.commit('resetState');
      router.replace(`/idle/${devId}`);
    },
    clearInt(){
      clearInterval(this.language);
    },
    setDifficulty(state,payload) {
      state.commit('setDifficulty',payload);
      if(payload === 1 ) {
        filteredTurns = state.gameTurns.filter(turn => turn.isBeginner);
        state.commit('setGameTurns',filteredTurns);
      }
    },
    fetchGameId(state, deviceId) {
      return new Promise((resolve,reject) => {
        API.get(`/gameid/${deviceId}`).then((response) => {
          // console.log("GAMEID", response.data.gameId);
          state.commit("setGameId", response.data.gameId);
          resolve(response)
        },(error) => {
          reject(error);
        });

      })
    },
    //Historic
    saveBalanceChange(state, payload) {
      // console.log("payload",payload);
      // console.log("gameplayiD",state.getters.gameplayId);
      let data = {balance_change: payload.balance, gameplay_id: state.getters.gameplayId};
      API.post("/balance",data);
    },
    storeGameplayEvent({commit,state}, event) {
      //Find GameTurn
      // console.log("STOREGAMEPLAYEVENT", event.event);
      // console.log("STOREGAMEPLAYEVENTROUND", event.round);
      let turnId = null;
      let actualGameTurnId = null;
      //Játék specifikus
      if(event.round === 4 && event.event === 'round_start' && state.difficulty === 1) {
          state.gameTurns.forEach((turn,i) => {
            if(turn.round == 3 && turn.event == 'action') {
              // console.log("I",i);
              // console.log("round4 start");
              actualGameTurnId = turn.gameTurnId;
            }
          })
      } else {
        // console.log("storegameplayelse")
        state.gameTurns.forEach((turn,i) => {
          if(event.event == turn.event && event.round == turn.round) {
            //turnId = turn.gameTurnId;
            if(state.gameTurns.length > i+1) {
              // console.log("I",i)
              // console.log("turn", state.gameTurns[i+1].gameTurnId);
              actualGameTurnId = state.gameTurns[i+1].gameTurnId;
            }
          }
        });
      }
      
      let eventObj = {
        device_id: state.deviceId,
        game_id: state.gameId,
        //game_turn_id: turnId,
        status: null,
        user_id: state.visitor.visitorId,
        difficulty: state.visitor.difficulty ? state.visitor.difficulty : 1,
        language: state.visitor.language ? state.visitor.language : 'hu',
        current_balance: event?.profit ? event.profit : state.visitor.balance ,
        mode: state.visitor.isChild ? "child" : "adult",
        gameplay_id: state.gameplayId,
        ...event,
        group_id: state.visitor.groupId
      }
      // console.log("evObj",eventObj);
      return new Promise((resolve, reject) => {
        API.post("/gameplay-event", eventObj).then(
          (response) => {
            //defaultCommits
            //console.log("gameplayEventResponse",response.data);
            if(response.data.gameplay_id) commit('setGameplayId', response.data.gameplay_id) 
            if(response.data.news_group_index) commit('setNewsGroupIndex', response.data.news_group_index);
            commit('setActualGameTurnId', actualGameTurnId);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    storeLog(state, log) {
      API.post("/log", event).then((response) => alert("200"));
    },
    logout(state, deviceId) {
      API.get(`/logout/${deviceId}`).then((response) =>
        console.log("reset state")
      );
    },
    updateVisitor(state, visitor) {
      API.put(`/visitor/${visitor.id}`, visitor).then((response) => alert("Hi"));
    },
    resetState(state) {
      state.commit("resetState");
    },
    async fetchGameTurns(state) {
        return new Promise((resolve,reject) => {
          API.get('https://jkr.cubicfoxdev.com/api/jkr/config').then(response => {
            state.commit('setGameTurns', response.data[3].gameTurns);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        })
    },
  },
  modules: {
    visitor: VisitorModule,
  },
  getters: {
    difficulty: (state) => state.difficulty,
    language: (state) => state.language,
    volume: (state) => state.volume,
    reservedByGroupId: (state) => state.groupId,
    groupArrivalTime: (state) => state.groupArrivalTime,
    status: (state) => state.status,
    polling: (state) => state.polling,
    isGameStarted: (state) => state.isGameStarted,
    deviceId: (state) => state.deviceId,
    routeName: (state) => state.routeName,
    languageInterval: (state) => state.languageInterval,
    exchangeDecision: (state) => state.exchangeDecision,
    deviza: (state) => state.deviza,
    securitiesAmountSum: (state) => state.securitiesAmountSum,
    devizaBonus: (state) => state.devizaBonus,
    profit: (state) => state.profit,
    actualGameTurnId: state => state.actualGameTurnId,
    stopGameTurnId: state => state.stopGameTurnId,
    gameplayId: state => state.gameplayId,
    state: state => state.state,
    //standbyTimeout: state => state.standbyTimeout,
    newsGroupIndex: state => state.newsGroupIndex,
    bestUserId: state => state.bestUserId,
    modalShow: state => state.modalShow,
    authenticated: state => state.authenticated,
  },
});
