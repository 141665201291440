<template>
    <div class="header-card-timer">
        <svg viewBox="-10 -10 220 220">
            <defs>
                <linearGradient id="myGradient" gradientTransform="rotate(90)">
                    <stop offset="5%" stop-color="#D11128" />
                    <stop offset="40%" stop-color="#BD1BD7" />
                    <stop offset="85%" stop-color="#2395EE" />
                </linearGradient>
            </defs>
            <path 
              id="timer-circle" 
              class="timer-circle" d="M0,100 a100,100 0 1 0 200,0 a100,100 0 1 0 -200,0" 
              stroke="#fff" 
              stroke-width="20"
            />
            <path 
              id="timer-path-remaining" 
              class="timer-path-remaining" 
              
              d="M100,0 a100,100 0 0 1 0,200 a100,100 0 0 1 0,-200,0" 
              stroke="url('#myGradient')" 
              stroke-width="20" 
              stroke-dasharray="630" 
              :stroke-dashoffset="this.circleDasharray"
            />
        </svg>
        <div class="header-card-timer-number">
            {{timeLeft}}
        </div>
    </div>
</template>

<script>
export default {
    props: ["startTime" ,"size", "radius"],
  data() {
    return {
      timeLimit: 0,
      timePassed: 0,
      timeLeft: 0,
      timeInterval: null,
      remainingPathColor: "green",
      fullDashArray: 0,
      warningTreshold: 10,
      alertTreshold: 5,
     circleDasharray: 0,
    };
  },
  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
      if(this.startTime.option === 'result') {
        return this.$emit('resultEnded');
      }
      return this.$emit('roundEnded');
    },

    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed = this.timePassed += 1;
        this.timeLeft -= 1;
        this.setCircleDasharray();

        if (this.timeLeft === 0) {
          this.onTimesUp();
        }
      }, 1000);
    },

    setCircleDasharray() {
      if(this.timePassed * (630/(this.startTime.time - 1)) <= 630) {
        this.circleDasharray = this.timePassed * (630/(this.startTime.time - 1));
      }
    },

    resetTime() {
      this.timePassed = 0;
      this.circleDasharray = 0;
      this.timeLeft = this.startTime.time;
      this.timeLimit = this.startTime.time;
      if(this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      this.startTimer();
    }
  },
  created() {
    this.timeLimit = this.startTime.time;
    this.timeLeft = this.startTime.time;
      this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  watch: {
    
    startTime: {
      deep: true,
      handler(newTime,oldTime) {
      console.log("VÁLTOZTIK");
      console.log(newTime);
      if(newTime) {
        console.log("belép new time");
        this.resetTime();
      }
    }
    }
  }
};
</script>

<style scoped>
svg {
  box-shadow: none !important;
}
</style>